import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-onboarding-heading',
  standalone: true,
  imports: [CommonModule, RouterLink, ],
  template: `
    <div class="heading__container mt-4 mb-4">
      @if(showBackLink) {
      <a
        (click)="goBack()"
        class="back-link flex text-sm font-light cursor-pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
        >
          <path
            d="M12.5 4.66663L7.5 10.5L12.5 16.3333"
            stroke="#171717"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <span class="hidden xl:block self-end">Back</span>
      </a>
      }

      <!-- Page Title -->
      <h1 class="text-xl text-mango-grey font-extrabold ">
        <span>{{ pageTitle }}</span>
      </h1>

      <div [ngClass]="{ 'cursor-not-allowed': !skipLink }">
        <a
          [routerLink]="skipLink"
          [ngClass]="{ 'pointer-events-none ': !skipLink }"
          class="font-extrabold text-mango text-sm skip-link"
          >Skip</a
        >
      </div>
    </div>
  `,
  styleUrl: './onboarding-heading.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingHeadingComponent {
  @Input() pageTitle!: string;
  @Input() backLink!: string[];
  @Input() showBackLink: boolean = true;
  @Input() skipLink!: string;

  goBack(): void {
    history.back();
  }
}
